export default {
    production: true,
    preview: '${PREVIEW}',
    environment: '${ENVIRONMENT}',
    bffUrl: '${BFF_URL}',
    cloudApiUrl: '${CLOUD_API_URL}',
    cmsUrl: '${CMS_URL}',
    cmsJWT: '${CMS_JWT}',
    algoliaID: '${ALGOLIA_ID}',
    algoliaSearchKey: '${ALGOLIA_SEARCH_KEY}',
    googleMapsApiKey: 'AIzaSyBBv-6-iGljjOO3UFnk4jcFc4dqEvzGoMc',
    sentryDsn: '${SENTRY_DSN}',
    sentryAuthToken: '${SENTRY_AUTH_TOKEN}',
    releaseVersion: '${RELEASE_VERSION}',
};
